import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CustomerAddress, useGetPostalCodeDetailsLazyQuery } from '~/operations';
import Yup from '~/utils/yup';
import { getRegionId } from '~/utils/getRegionId';
import { useCallback, useState } from 'react';

export const schema = Yup.object().shape({
  company: Yup.string().required('Campo é obrigatório'),
  postcode: Yup.string().required('Campo é obrigatório').postalCode('Formato do cep é inválido'),
  street: Yup.string().required('Campo é obrigatório').max(54, 'Valor deve ter 54 caracteres ou menos'),
  number: Yup.string().required('Campo é obrigatório').max(5, 'Valor deve ter 5 caracteres'),
  complement: Yup.string().max(14, 'Valor deve ter 14 caracteres ou menos'),
  neighborhood: Yup.string().required('Campo é obrigatório').max(45, 'Valor deve ter 45 caracteres ou menos'),
  city: Yup.string().required('Campo é obrigatório').max(50, 'Valor deve ter 50 caracteres ou menos'),
  region: Yup.object({
    region: Yup.string().required('Campo é obrigatório'),
    region_id: Yup.number().required('Campo é obrigatório'),
  }).required('Campo é obrigatório'),
});

interface UseAddressFormProps {
  address: CustomerAddress;
}

export const useAddressForm = ({ address }: UseAddressFormProps) => {
  const [postalCodeError, setPostalCodeError] = useState(false);
  const form = useForm<CustomerAddress>({
    defaultValues: address,
    resolver: yupResolver(schema),
  });

  const [getPostalCodeDetails] = useGetPostalCodeDetailsLazyQuery({
    onCompleted: ({ getPostalCodeDetails: data }) => {
      if (data === null) {
        setPostalCodeError(true);
        return;
      }
      if (data?.street) form.setValue('street', `${data?.streetType} ${data?.street}`);
      form.setValue('neighborhood', data?.neighborhood || '');
      form.setValue('city', data?.city || '');
      if (data?.stateCode) {
        const regionCode = getRegionId(data.stateCode);
        if (regionCode) {
          form.setValue('region', { region: data.stateCode, region_id: parseInt(regionCode, 10) });
        }
      }
    },
  });

  const fetchPostalInformation = useCallback(async (v) => {
    setPostalCodeError(false);
    const postalCode = (v.target.value || '').replace(/[^\d]/g, '');
    if (!postalCode || postalCode.length < 8) return;
    getPostalCodeDetails({ variables: { postalCode } });
  }, []);

  return { form, fetchPostalInformation, postalCodeError };
};

export default useAddressForm;
