import { DataContainer, DropdownOptions, Radio, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useState } from 'react';
import { CheckoutDeleteCardModal } from '~/components/forms/CheckoutDeleteCardModal';
import { PaymentToken } from '~/operations';
import { getPublicPath } from '~/utils/getPublicPath';
import { formatLastFour } from '~/utils/subscription';
import styles from './PaymentCard.module.scss';

export interface PaymentCard {
  token: Partial<PaymentToken>;
  allowDelete?: boolean;
  onSelect?: (token: Partial<PaymentToken>) => void;
  selected?: boolean;
  className?: string;
  compact?: boolean;
}

export const PaymentCard = ({
  className,
  token,
  allowDelete = false,
  onSelect,
  selected = false,
  compact = false,
}: PaymentCard) => {
  const [deleteModal, showDeleteModal] = useState(false);

  const infos = JSON.parse(token.details || '');

  const options: Array<DropdownOptions> = [];
  if (allowDelete)
    options.push({ label: 'Excluir', onClick: () => showDeleteModal(true), className: 'color-red-ziyou' });

  if (!token || !token.public_hash) return null;

  return (
    <DataContainer
      options={options}
      className={clsx(
        className,
        styles.paymentCard,
        onSelect && 'cursor-pointer',
        selected && styles.selected,
        compact && styles.compact
      )}
      onClick={() => onSelect && onSelect(token)}
    >
      <div className={styles.paymentInfoContainer}>
        {onSelect && <Radio name="selectedCard" checked={selected} labelClassName="w-auto m-0" />}
        <div className={styles.card}>
          <img src={getPublicPath(`/cc-bandeiras/${infos.type}.png`)} />
        </div>
        <Typography variant="h4" weight="bold">
          {formatLastFour(infos.Number)}
        </Typography>
      </div>
      <CheckoutDeleteCardModal
        publicHash={token.public_hash}
        isOpen={deleteModal}
        closeModal={() => showDeleteModal(false)}
        title="Excluir cartão"
      />
    </DataContainer>
  );
};

export default PaymentCard;
