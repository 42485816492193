import { Footer, Header } from '~/components/structure';
import styles from './MaintenanceLayout.module.scss';

export interface MaintenanceLayoutProps {
  compactFooter?: boolean;
  maintenance?: boolean;
}

export const MaintenanceLayout: React.FC<MaintenanceLayoutProps> = ({ children, compactFooter, maintenance }) => (
  <div className={styles.container}>
    <header>
      <Header maintenance={maintenance} />
    </header>
    <main className={styles.main}>{children}</main>
    <footer>
      <Footer compact={compactFooter} />
    </footer>
  </div>
);

export default MaintenanceLayout;
