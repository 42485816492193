import { Form, Input, Label, Modal, TextfieldCommonMasks } from '@objectedge/ziyou-storefront-ds';
import styles from './EditPaymentAddressModal.module.scss';

export interface EditPaymentAddressModalProps {
  modal: boolean;
  closeModal: () => void;
}

export const EditPaymentAddressModal = (props: EditPaymentAddressModalProps) => {
  const modal = props.modal;

  const ufs = [
    { label: 'AC', value: '485' },
    { label: 'AL', value: '486' },
    { label: 'AP', value: '487' },
    { label: 'AM', value: '488' },
    { label: 'BA', value: '489' },
    { label: 'CE', value: '490' },
    { label: 'ES', value: '491' },
    { label: 'GO', value: '492' },
    { label: 'MA', value: '493' },
    { label: 'MT', value: '494' },
    { label: 'MS', value: '495' },
    { label: 'MG', value: '496' },
    { label: 'PA', value: '497' },
    { label: 'PB', value: '498' },
    { label: 'PR', value: '499' },
    { label: 'PE', value: '500' },
    { label: 'PI', value: '501' },
    { label: 'RJ', value: '502' },
    { label: 'RN', value: '503' },
    { label: 'RS', value: '504' },
    { label: 'RO', value: '505' },
    { label: 'RR', value: '506' },
    { label: 'SC', value: '507' },
    { label: 'SP', value: '508' },
    { label: 'SE', value: '509' },
    { label: 'TO', value: '510' },
    { label: 'DF', value: '511' },
  ];

  return (
    <Modal
      isOpen={modal}
      size="lg"
      title="Editar endereço de cobrança"
      okHandler={props.closeModal}
      cancelHandler={props.closeModal}
      okButtonLabel="Salvar"
      cancelButtonLabel="Cancelar"
    >
      <div className={styles['main']}>
        <Form>
          <div className={styles['row']}>
            <div className={styles['item']}>
              <Label>Nome do Lugar</Label>
              <Input className={styles['textfield']} placeholder="Minha casa" />
            </div>
            <div className={styles['item']}>
              <Label>CEP</Label>
              <Input className={styles['textfield']} placeholder="00000-000" mask={TextfieldCommonMasks.cep} />
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['item']}>
              <Label>Endereço</Label>
              <Input className={styles['textfield']} placeholder="Rua ..." />
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['item']}>
              <Label>Número</Label>
              <Input className={styles['textfield']} placeholder="nº" />
            </div>
            <div className={styles['item']}>
              <Label>Complemento</Label>
              <Input className={styles['textfield']} placeholder="Aptº, bloco, quadra..." />
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['item']}>
              <Label>Bairro</Label>
              <Input className={styles['textfield']} placeholder="Bairro" />
            </div>
            <div className={styles['item']}>
              <Label>Cidade</Label>
              <Input className={styles['textfield']} placeholder="Cidade" />
            </div>
            <div className={styles['item']}>
              <Label>Estado</Label>
              <select name="uf" id="uf" className={styles['select']}>
                {ufs.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditPaymentAddressModal;
