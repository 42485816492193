import { EmailCard, PasswordCard } from '~/components/perfil';
import { Customer } from '~/operations';

export interface SecurityDataProps {
  id?: string;
  customer: Partial<Customer>;
}

export const SecurityData = ({ id, customer }: SecurityDataProps) => (
  <div id={id}>
    <EmailCard customer={customer} />
    <PasswordCard />
  </div>
);

export default SecurityData;
