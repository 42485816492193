import { DataContainer, Typography } from '@objectedge/ziyou-storefront-ds';
import { useState } from 'react';
import { EditPersonalDataModal } from '~/components/perfil';
import { Customer, DocumentType } from '~/operations';
import { formatDocument, formatPhoneNumber, getGender } from '~/utils/customer';
import { formatDate } from '~/utils/format';
import EditCompanyDataModal from '../EditCompanyDataModal';

export interface PersonalDataProps {
  id?: string;
  customer: Partial<Customer>;
}

export const PersonalData = ({ id, customer }: PersonalDataProps) => {
  const [edit, setEdit] = useState(false);
  const options = [{ label: 'Editar', onClick: () => setEdit(true) }];

  const isCPF = customer.documentType === DocumentType.Cpf;
  const EditComponent = isCPF ? EditPersonalDataModal : EditCompanyDataModal;

  return (
    <div id={id}>
      <DataContainer options={options}>
        <Typography variant="h5">
          {isCPF ? `${customer.firstname} ${customer.lastname}` : customer.commercialName}
        </Typography>
        <Typography variant="body-text-2" className="mb-0">
          {isCPF ? (
            <>
              {getGender(customer.gender).label}
              <br />
              {formatDate(customer.date_of_birth, { utc: true })}
            </>
          ) : (
            <>
              {customer.companyName}
              <br />
              Inscrição Estadual: {customer.stateRegistration}
            </>
          )}
          <br />
          {customer.documentType} {formatDocument(customer.documentNumber, customer.documentType || 'CPF')}
          <br />
          {formatPhoneNumber(customer.phoneNumber)}
        </Typography>
      </DataContainer>
      <EditComponent modal={edit} closeModal={() => setEdit(false)} customer={customer} />
    </div>
  );
};

export default PersonalData;
