import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, Modal } from '@objectedge/ziyou-storefront-ds';
import { useForm } from 'react-hook-form';
import { useUpdateCustomerEmailMutation } from '~/operations';
import { validation } from '~/utils/customer';

export interface EditEmailModalProps {
  modal: boolean;
  closeModal: () => void;
  email: string;
}

const schema = validation.getSchema({ email: 1, password: 1 });

export const EditEmailModal = ({ modal, closeModal, email }: EditEmailModalProps) => {
  const [updateCustomer, { loading: saving }] = useUpdateCustomerEmailMutation();

  async function onSubmit(values) {
    if (values.email === email) {
      setError('email', { message: 'Email é o mesmo ao já cadastrado.' });
      return;
    }

    try {
      const { data } = await updateCustomer({ variables: { ...values } });
      if (data === null) throw 'no-data';
      closeModal();
    } catch (err) {
      setError('password', { message: 'Senha inválida ou email já possui outro cadastro.' });
    }
  }

  const { handleSubmit, errors, register, setError } = useForm({
    defaultValues: { email, password: '' },
    resolver: yupResolver(schema),
  });

  return (
    <Modal
      isOpen={modal}
      size="lg"
      title="Editar e-mail"
      okHandler={handleSubmit(onSubmit)}
      cancelHandler={closeModal}
      okButtonLabel="Salvar"
      okDisabled={saving}
      cancelButtonLabel="Cancelar"
    >
      <Form className="d-flex flex-column flex-lg-row">
        <div className="w-100 mr-3">
          <Input
            label="Novo e-mail"
            name="email"
            placeholder="nome@email.com"
            ref={register}
            status={errors.email ? 'danger' : 'default'}
            helperText={errors.email?.message ?? ''}
          />
        </div>
        <div className="w-100">
          <Input
            label="Senha"
            name="password"
            type="password"
            ref={register}
            className="fs-exclude"
            status={errors.password ? 'danger' : 'default'}
            helperText={errors.password?.message ?? ''}
            canToggleVisibility
          />
        </div>
      </Form>
    </Modal>
  );
};

export default EditEmailModal;
