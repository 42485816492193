import { Footer, Header } from '~/components/structure';
import styles from './CartLayout.module.scss';

export const CartLayout: React.FC = ({ children }) => (
  <div className={styles.container}>
    <header>
      <Header />
    </header>
    <main className={styles.main}>{children}</main>
    <footer>
      <Footer compact />
    </footer>
  </div>
);

export default CartLayout;
