import { AddNewButton } from '@objectedge/ziyou-storefront-ds';
import { useState } from 'react';
import { CheckoutEditOrAddCardModal } from '~/components/forms/CheckoutEditOrAddCardModal';
import { PaymentCard } from '~/components/perfil';
import { PaymentToken } from '~/operations';

export interface PaymentDataProps {
  id?: string;
  paymentTokens: Array<Partial<PaymentToken>>;
}

export const PaymentData = ({ id, paymentTokens }: PaymentDataProps) => {
  const [createCardModal, showCreateCardModal] = useState(false);

  if (!paymentTokens) return null;

  return (
    <div id={id}>
      {paymentTokens.map((card) => (
        <PaymentCard key={card.public_hash} token={card} className="mb-2" allowDelete />
      ))}
      <AddNewButton onClick={() => showCreateCardModal(true)} />
      <CheckoutEditOrAddCardModal
        isOpen={createCardModal}
        sendModal={() => showCreateCardModal(!createCardModal)}
        closeModal={() => showCreateCardModal(!createCardModal)}
        title="Novo cartão"
      />
    </div>
  );
};

export default PaymentData;
