import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, Modal } from '@objectedge/ziyou-storefront-ds';
import { useForm } from 'react-hook-form';
import PasswordValidation from '~/components/forms/PasswordValidation';
import { useUpdateCustomerPasswordMutation } from '~/operations';
import { validation } from '~/utils/customer';
import { useAlertsContext } from '~/utils/useAlerts';

export interface EditPasswordModalProps {
  modal: boolean;
  closeModal: () => void;
}

const schema = validation.getSchema({ password: 1, validPassword: 'newPassword' });

export const EditPasswordModal = ({ modal, closeModal }: EditPasswordModalProps) => {
  const [changeCustomerPassword, { loading: saving }] = useUpdateCustomerPasswordMutation();
  const { actions: alertsActions } = useAlertsContext();

  const onSubmit = async (values) => {
    if (values.password === values.newPassword) {
      setError('password', { message: 'Nova senha é igual a senha atual' });
      return;
    }

    try {
      const { data } = await changeCustomerPassword({
        variables: {
          currentPassword: values.password,
          newPassword: values.newPassword,
        },
      });
      if (data === null) throw 'error';
      alertsActions.addSuccessAlert('Nova senha salva com sucesso!');
      closeModal();
    } catch (err) {
      setError('password', { message: 'Senha incorreta' });
    }
  };

  const { handleSubmit, errors, watch, register, setError } = useForm({
    defaultValues: { password: '', newPasword: '' },
    resolver: yupResolver(schema),
  });

  const newPassword = watch('newPassword');

  return (
    <Modal
      isOpen={modal}
      size="lg"
      title="Editar senha"
      okHandler={handleSubmit(onSubmit)}
      okDisabled={saving}
      cancelHandler={closeModal}
      okButtonLabel="Salvar"
      cancelButtonLabel="Cancelar"
    >
      <Form className="d-flex flex-column">
        <Input
          label="Senha atual"
          name="password"
          type="password"
          ref={register}
          className="fs-exclude"
          status={errors.password ? 'danger' : 'default'}
          helperText={errors.password?.message ?? ''}
          canToggleVisibility
        />
        <div className="d-flex flex-column flex-lg-row">
          <Input
            label="Nova senha"
            name="newPassword"
            type="password"
            ref={register}
            status={errors.newPassword ? 'danger' : 'default'}
            helperText={errors.newPassword?.message ? 'Nova senha inválida' : ''}
            canToggleVisibility
            className="w-100 w-md-50 fs-exclude"
          />
          <PasswordValidation password={newPassword} className="w-100 w-md-50 mt-lg-5 ml-4" />
        </div>
      </Form>
    </Modal>
  );
};

export default EditPasswordModal;
