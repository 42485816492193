import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Form, Input, Modal, Select, TextfieldCommonMasks } from '@objectedge/ziyou-storefront-ds';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Customer, useUpdateCustomerPersonalDataMutation } from '~/operations';
import { formatDocument, formatPhoneNumber, genderOptions, getGender, validation } from '~/utils/customer';
import { formatDate } from '~/utils/format';
import { useAlertsContext } from '~/utils/useAlerts';
import styles from './EditPersonalDataModal.module.scss';

const schema = validation.getSchema({
  name: 'firstname',
  lastName: 'lastname',
  gender: 1,
  phone: 'phoneNumber',
  anotherPhone: 'alternatePhoneNumber',
});

const getDefaultValues = (customer) => ({
  firstname: customer.firstname || '',
  lastname: customer.lastname || '',
  gender: getGender(customer.gender),
  phoneNumber: formatPhoneNumber(customer.phoneNumber),
  alternatePhoneNumber: formatPhoneNumber(customer.alternatePhoneNumber),
  is_subscribed: customer.is_subscribed || false,
});

type UpdateCustomerData = {
  firstname: string;
  lastname: string;
  phoneNumber: string;
  alternatePhoneNumber: string;
  is_subscribed: boolean;
  gender: { label: string; value: number };
};

export interface EditPersonalDataModalProps {
  modal: boolean;
  customer: Partial<Customer>;
  closeModal: () => void;
}

export const EditPersonalDataModal = ({ customer, modal, closeModal }: EditPersonalDataModalProps) => {
  const [updateCustomer, { loading: saving }] = useUpdateCustomerPersonalDataMutation();
  const { actions: alertsActions } = useAlertsContext();

  const onSubmit = async (values: UpdateCustomerData) => {
    try {
      await updateCustomer({
        variables: {
          ...values,
          gender: values.gender.value,
          phoneNumber: (values.phoneNumber || '').replace(/[^\d]/g, ''),
          alternatePhoneNumber: (values.alternatePhoneNumber || '').replace(/[^\d]/g, ''),
        },
      });
      closeModal();
    } catch (err) {
      alertsActions.addDangerAlert('Preencha todos os campos corretamente');
    }
  };

  const { register, handleSubmit, errors, control, reset } = useForm<UpdateCustomerData>({
    defaultValues: getDefaultValues(customer),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(getDefaultValues(customer));
  }, [modal, customer]);

  return (
    <Modal
      isOpen={modal}
      size="lg"
      title="Editar dados pessoais"
      okHandler={handleSubmit(onSubmit)}
      cancelHandler={closeModal}
      okButtonLabel="Salvar"
      okDisabled={saving}
      cancelButtonLabel="Cancelar"
    >
      <div className={styles.main}>
        <Form>
          <div>
            <div>
              <Input
                label="Nome"
                name="firstname"
                ref={register}
                status={errors.firstname ? 'danger' : 'default'}
                helperText={errors.firstname?.message ?? ''}
                maxLength={30}
              />
            </div>
            <div>
              <Input
                label="Sobrenome"
                name="lastname"
                ref={register}
                status={errors.lastname ? 'danger' : 'default'}
                helperText={errors.lastname?.message ?? ''}
                maxLength={50}
              />
            </div>
            <div>
              <Controller
                name="gender"
                control={control}
                label="Sexo"
                placeholder="Selecione"
                status={errors.gender ? 'danger' : 'default'}
                helperText={errors.gender?.label?.message ?? ''}
                options={genderOptions}
                as={Select}
              />
            </div>
          </div>
          <div>
            <div>
              <Input label="Data de Nascimento" disabled value={formatDate(customer.date_of_birth)} />
            </div>
            <div>
              <Input
                label={customer.documentType}
                disabled
                value={formatDocument(customer.documentNumber, customer.documentType)}
              />
            </div>
          </div>
          <div>
            <div>
              <Controller
                control={control}
                label="Seu telefone"
                name="phoneNumber"
                status={errors.phoneNumber ? 'danger' : 'default'}
                helperText={errors.phoneNumber?.message ?? ''}
                mask={TextfieldCommonMasks.phone}
                as={Input}
              />
            </div>
            <div>
              <Controller
                control={control}
                label="Outro telefone (opcional)"
                name="alternatePhoneNumber"
                status={errors.alternatePhoneNumber ? 'danger' : 'default'}
                helperText={errors.alternatePhoneNumber?.message ?? ''}
                mask={TextfieldCommonMasks.phone}
                as={Input}
              />
            </div>
          </div>
          <div>
            <div>
              <Checkbox
                id="is_subscribed"
                name="is_subscribed"
                innerRef={register}
                label="Quero receber e-mail, WhatsApp, e SMS da ZiYou a respeito da(s) minha(s) assinatura(s) e novidades da ZiYou."
              />
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditPersonalDataModal;
