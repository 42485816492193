import { Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './SidebarMenu.module.scss';

export const SidebarMenu = () => {
  const router = useRouter();

  const scrollTo = (anchor) => (e) => {
    if (typeof document === 'undefined') return;
    const element = document.getElementById(anchor);

    if (router.pathname === '/minha-conta') {
      e.preventDefault();
      window.scrollTo({
        top: (element?.offsetTop || 0) + 80,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={styles.sidebarMenu}>
      <Typography variant="h5" className={clsx(router.pathname === '/minha-conta' && styles.selected)}>
        Meu perfil
      </Typography>
      <div className="pl-4">
        <Link href="/minha-conta#dados-pessoais">
          <a onClick={scrollTo('dados-pessoais')} id="myaccount-menu-personal-info">
            <Typography variant="subtitle-1">Dados pessoais</Typography>
          </a>
        </Link>
        <Link href="/minha-conta#enderecos">
          <a onClick={scrollTo('enderecos')} id="myaccount-menu-addresses">
            <Typography variant="subtitle-1">Meus endereços</Typography>
          </a>
        </Link>
        <Link href="/minha-conta#seguranca">
          <a onClick={scrollTo('seguranca')} id="myaccount-menu-security">
            <Typography variant="subtitle-1">Segurança</Typography>
          </a>
        </Link>
        {/* <Link href="/minha-conta#pagamento">
          <a onClick={scrollTo('pagamento')} id="myaccount-menu-payment-methods">
            <Typography variant="subtitle-1">Formas de pagamento</Typography>
          </a>
        </Link> */}
      </div>
      <Link href="/minha-conta/minhas-assinaturas">
        <a id="myaccount-menu-subscriptions">
          <Typography
            variant="h5"
            className={clsx(router.pathname.startsWith('/minha-conta/minhas-assinaturas') && styles.selected)}
          >
            Minhas assinaturas
          </Typography>
        </a>
      </Link>
    </div>
  );
};

export default SidebarMenu;
