import clsx from 'clsx';
import { Footer, Header } from '~/components/structure';
import styles from './CheckoutLayout.module.scss';

export interface CheckoutLayoutProps {
  hasGrayBg?: boolean;
}

export const CheckoutLayout: React.FC<CheckoutLayoutProps> = ({ children, hasGrayBg = false }) => (
  <div
    className={clsx(
      styles.container,
      hasGrayBg && styles['container--with-gray-bg'],
      !hasGrayBg && styles['container--with-white-bg']
    )}
  >
    <header>
      <Header compact />
    </header>
    <main className={styles.main}>{children}</main>
    <footer>
      <Footer compact />
    </footer>
  </div>
);

export default CheckoutLayout;
