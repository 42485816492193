import { AddNewButton } from '@objectedge/ziyou-storefront-ds';
import { useState } from 'react';
import { AddressCard, CreateOrEditAddressModal } from '~/components/perfil';
import { Customer } from '~/operations';

export interface AddressDataProps {
  id?: string;
  customer: Partial<Customer>;
}

export const AddressData = ({ id, customer }: AddressDataProps) => {
  const [isCreateModalOpen, showCreateModal] = useState(false);

  const addresses = customer.addresses || [];
  const customerData = {
    firstname: customer.firstname,
    lastname: customer.lastname,
    phoneNumber: customer.phoneNumber,
  };

  return (
    <div id={id}>
      {addresses.map(
        (address) =>
          address && (
            <AddressCard
              key={address.id || undefined}
              address={address}
              customer={customerData}
              allowDelete={addresses.length > 1}
            />
          )
      )}
      <AddNewButton onClick={() => showCreateModal(true)} />
      <CreateOrEditAddressModal
        customer={customerData}
        isOpen={isCreateModalOpen}
        closeModal={() => showCreateModal(false)}
      />
    </div>
  );
};

export default AddressData;
