import { Typography } from '@objectedge/ziyou-storefront-ds';
import styles from './Title.module.scss';

export interface TitleProps {
  id?: string;
  text: string;
}

const Title = (props: TitleProps) => (
  <div id={props.id}>
    <Typography className={styles['text']} variant="h1" weight="bold">
      {props.text}
    </Typography>
  </div>
);

export default Title;
