import { DataContainer, DropdownOptions, Typography } from '@objectedge/ziyou-storefront-ds';
import { useState } from 'react';
import { EditPasswordModal } from '~/components/perfil';

export const PasswordCard = () => {
  const [modal, showModal] = useState(false);
  const options: DropdownOptions[] = [{ label: 'Alterar', onClick: () => showModal(true) }];

  return (
    <DataContainer options={options} className="mt-2">
      <Typography variant="h5">Senha</Typography>
      <Typography variant="body-text-2" className="mb-0">
        ********
      </Typography>
      {modal && <EditPasswordModal modal={true} closeModal={() => showModal(false)} />}
    </DataContainer>
  );
};

export default PasswordCard;
