import { DataContainer, DropdownOptions, Typography } from '@objectedge/ziyou-storefront-ds';
import { useState } from 'react';
import { CreateOrEditAddressModal } from '~/components/perfil/CreateOrEditAddressModal';
import {
  Customer,
  CustomerAddress,
  DeleteCustomerAddressMutation,
  useDeleteCustomerAddressMutation,
} from '~/operations';
import { deleteCustomerAddress as updateDeleteCustomerAddress } from '~/state/cache/update';
import { formatPostalCode } from '~/utils/customer';
import { useAlertsContext } from '~/utils/useAlerts';

const optimisticResponse: DeleteCustomerAddressMutation = {
  __typename: 'Mutation',
  deleteCustomerAddress: true,
};

export interface AddressCardProps {
  address: CustomerAddress;
  customer: Pick<Customer, 'firstname' | 'lastname' | 'phoneNumber'>;
  allowDelete: boolean;
}

export const AddressCard = ({ address, customer, allowDelete }: AddressCardProps) => {
  const id = address.id || 0;
  const [deleteCustomerAddress] = useDeleteCustomerAddressMutation({
    update: updateDeleteCustomerAddress(id),
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { actions: alertsActions } = useAlertsContext();

  const handleDelete = async () => {
    if (address?.default_shipping || address?.default_billing) return;
    deleteCustomerAddress({ variables: { id }, optimisticResponse });
    alertsActions.addSuccessAlert('Endereço deletado com sucesso');
  };

  const options: Array<DropdownOptions> = [{ label: 'Editar', onClick: () => setModalIsOpen(true) }];
  if (allowDelete && !address?.default_shipping && !address?.default_billing) {
    options.push({ label: 'Excluir', onClick: handleDelete, className: 'color-red-ziyou' });
  }

  return (
    <DataContainer className="mb-2 text-break" options={options}>
      <Typography variant="h5">{address?.company}</Typography>
      <Typography variant="body-text-2" className="mb-0">
        {address?.street?.[0]}, {address?.number}
        {address?.complement ? `, ${address.complement}` : ''}
        <br />
        {address?.neighborhood} - {address?.city} - {address?.region?.region}
        <br />
        {formatPostalCode(address?.postcode)}
      </Typography>

      <CreateOrEditAddressModal
        address={address}
        customer={customer}
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
      />
    </DataContainer>
  );
};

export default AddressCard;
