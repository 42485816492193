import { Col, Row } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { FeaturesList } from '~/components/marketing';
import { SidebarMenu } from '~/components/perfil';
import { Footer, Header } from '~/components/structure';
import styles from './MyAccountLayout.module.scss';

interface MyAccountLayoutProps {
  showFeaturesList?: boolean;
}

export const MyAccountLayout: React.FC<MyAccountLayoutProps> = ({ children, showFeaturesList = false }) => (
  <div className={styles.container}>
    <header>
      <Header />
    </header>
    <main>
      <div className={clsx(styles.main, 'container-lg pt-lg-5 mb-5 pb-5')}>
        <Row noGutters>
          <Col lg={3} className="pl-5 d-none d-lg-block">
            <SidebarMenu />
          </Col>
          <Col lg={9} xs={12}>
            {children}
          </Col>
        </Row>
      </div>
      {showFeaturesList && (
        <div className={clsx('container-fluid', styles.featureList)}>
          <FeaturesList />
        </div>
      )}
    </main>
    <footer>
      <Footer />
    </footer>
  </div>
);

export default MyAccountLayout;
