import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Form, Input, Modal, TextfieldCommonMasks } from '@objectedge/ziyou-storefront-ds';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Customer, useUpdateCompanyDataMutation } from '~/operations';
import { formatDocument, formatPhoneNumber, validation } from '~/utils/customer';
import { useAlertsContext } from '~/utils/useAlerts';
import styles from './EditCompanyDataModal.module.scss';

const schema = validation.getSchema({
  companyName: 1,
  tradingName: 'commercialName',
  phone: 'phoneNumber',
  anotherPhone: 'alternatePhoneNumber',
});

const getDefaultValues = (customer: Partial<Customer>) => ({
  commercialName: customer.commercialName || '',
  companyName: customer.companyName || '',
  phoneNumber: formatPhoneNumber(customer.phoneNumber),
  alternatePhoneNumber: formatPhoneNumber(customer.alternatePhoneNumber),
  is_subscribed: customer.is_subscribed || false,
});

type UpdateCustomerData = {
  commercialName: string;
  companyName: string;
  phoneNumber: string;
  alternatePhoneNumber: string;
  is_subscribed: boolean;
};

export interface EditCompanyDataModalProps {
  modal: boolean;
  customer: Partial<Customer>;
  closeModal: () => void;
}

export const EditCompanyDataModal = ({ customer, modal, closeModal }: EditCompanyDataModalProps) => {
  const [updateCustomer, { loading: saving }] = useUpdateCompanyDataMutation();
  const { actions: alertsActions } = useAlertsContext();

  const onSubmit = async (values: UpdateCustomerData) => {
    try {
      await updateCustomer({
        variables: {
          ...values,
          phoneNumber: (values.phoneNumber || '').replace(/[^\d]/g, ''),
          alternatePhoneNumber: (values.alternatePhoneNumber || '').replace(/[^\d]/g, ''),
        },
      });
      closeModal();
    } catch (err) {
      alertsActions.addDangerAlert('Preencha todos os campos corretamente');
    }
  };

  const { register, handleSubmit, errors, control, reset } = useForm<UpdateCustomerData>({
    defaultValues: getDefaultValues(customer),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(getDefaultValues(customer));
  }, [modal, customer]);

  return (
    <Modal
      isOpen={modal}
      size="lg"
      title="Editar dados cadastrais"
      okHandler={handleSubmit(onSubmit)}
      cancelHandler={closeModal}
      okButtonLabel="Salvar"
      okDisabled={saving}
      cancelButtonLabel="Cancelar"
    >
      <div className={styles.main}>
        <Form>
          <div>
            <div>
              <Input
                label="Nome Fantasia"
                name="commercialName"
                ref={register}
                status={errors.commercialName ? 'danger' : 'default'}
                helperText={errors.commercialName?.message ?? ''}
              />
            </div>
            <div>
              <Input
                label="Razão Social"
                name="companyName"
                ref={register}
                status={errors.companyName ? 'danger' : 'default'}
                helperText={errors.companyName?.message ?? ''}
              />
            </div>
          </div>
          <div>
            <div>
              <Input label="CNPJ" disabled value={formatDocument(customer.documentNumber, customer.documentType)} />
            </div>
            <div>
              <Input label="Inscrição Estadual" disabled value={customer.stateRegistration + ''} />
            </div>
          </div>
          <div>
            <div>
              <Controller
                control={control}
                label="Seu telefone"
                name="phoneNumber"
                status={errors.phoneNumber ? 'danger' : 'default'}
                helperText={errors.phoneNumber?.message ?? ''}
                mask={TextfieldCommonMasks.phone}
                as={Input}
              />
            </div>
            <div>
              <Controller
                control={control}
                label="Outro telefone (opcional)"
                name="alternatePhoneNumber"
                status={errors.alternatePhoneNumber ? 'danger' : 'default'}
                helperText={errors.alternatePhoneNumber?.message ?? ''}
                mask={TextfieldCommonMasks.phone}
                as={Input}
              />
            </div>
          </div>
          <div>
            <div>
              <Checkbox
                id="is_subscribed"
                name="is_subscribed"
                innerRef={register}
                label="Quero receber e-mail, WhatsApp, e SMS da ZiYou a respeito da(s) minha(s) assinatura(s) e novidades da ZiYou."
              />
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditCompanyDataModal;
