import { DataContainer, DropdownOptions, Typography } from '@objectedge/ziyou-storefront-ds';
import { useState } from 'react';
import { EditEmailModal } from '~/components/perfil';
import { Customer } from '~/operations';

export interface EmailCardProps {
  customer: Partial<Customer>;
}

export const EmailCard = ({ customer }: EmailCardProps) => {
  const [modal, showModal] = useState(false);

  const options: DropdownOptions[] = [{ label: 'Alterar', onClick: () => showModal(true) }];

  return (
    <DataContainer options={options}>
      <Typography variant="h5">Email</Typography>
      <Typography variant="body-text-2" className="mb-0">
        {customer.email}
      </Typography>
      {modal && <EditEmailModal modal={true} closeModal={() => showModal(false)} email={customer.email as string} />}
    </DataContainer>
  );
};

export default EmailCard;
