import clsx from 'clsx';
import { Footer, Header } from '~/components/structure';
import styles from './LoginLayout.module.scss';

export interface LoginLayoutProps {
  hasGrayBg?: boolean;
  hasStepper?: boolean;
}

export const LoginLayout: React.FC<LoginLayoutProps> = ({ children, hasGrayBg = false, hasStepper = false }) => (
  <div
    className={clsx(
      styles.container,
      hasGrayBg && styles['container--with-gray-bg'],
      !hasGrayBg && styles['container--with-white-bg']
    )}
  >
    <header>
      <Header compact />
    </header>
    <main
      className={clsx(
        styles.main,
        hasStepper && styles['main--with-stepper'],
        !hasStepper && styles['main--without-stepper']
      )}
    >
      {children}
    </main>
    <footer>
      <Footer compact />
    </footer>
  </div>
);

export default LoginLayout;
