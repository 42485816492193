import { AppointmentOjective, CustomerSubscriptionStatus, AppointmentConnection } from '~/operations';

export interface Subscription {
  id: string;
  submittedDate: Date;
  deliveryDate: Date;
  cancellationDate: Date;
  appointments: AppointmentConnection;
  status: string;
  address: {
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    postalCode: string;
    country: string;
    state: string;
    city: string;
  };
  items: [
    {
      skuId: string;
      sku: {
        name: string;
      };
    }
  ];
}

export interface Appointments {
  edges: [{ id: string; selectedDate: Date; objective: string }];
}

const statusDetails = {
  [CustomerSubscriptionStatus.Initial]: {
    name: 'Agendada',
    color: '#FFC107',
  },
  [CustomerSubscriptionStatus.InProgress]: {
    name: 'Ativa',
    color: '#28A745',
  },
  [CustomerSubscriptionStatus.Changed]: {
    name: 'Ativa',
    color: '#28A745',
  },
  [CustomerSubscriptionStatus.Cancelling]: {
    name: 'Cancelamento Iniciado',
    color: '#DEE3E8',
  },
  [CustomerSubscriptionStatus.Cancelled]: {
    name: 'Cancelada',
    color: '#DEE3E8',
  },
  [CustomerSubscriptionStatus.CancelledFraud]: {
    name: 'Cancelada',
    color: '#DEE3E8',
  },
};

const sortByDate = (data: Subscription[], sortBy: string): Subscription[] => {
  data.sort((subscription1, subscription2) =>
    subscription1[sortBy] < subscription2[sortBy] ? -1 : subscription1[sortBy] > subscription2[sortBy] ? 1 : 0
  );
  return data;
};

const sortByPickUpDate = (data: Subscription[]): Subscription[] => {
  data.sort((subscription1, subscription2) =>
    (subscription1?.appointments?.edges?.find((a) => a?.objective === AppointmentOjective.Collect)?.selectedDate ||
      '') <
    (subscription2?.appointments?.edges?.find((a) => a?.objective === AppointmentOjective.Collect)?.selectedDate || '')
      ? -1
      : (subscription1?.appointments?.edges?.find((a) => a?.objective === AppointmentOjective.Collect)?.selectedDate ||
          '') >
        (subscription2?.appointments?.edges?.find((a) => a?.objective === AppointmentOjective.Collect)?.selectedDate ||
          '')
      ? 1
      : 0
  );
  return data;
};

export const arrangeBystatus = (data) => {
  let arrangedSubscrptions = [new Array(), new Array(), new Array(), new Array()];
  data.map((subscription) => {
    if (subscription.status === CustomerSubscriptionStatus.Initial) {
      return arrangedSubscrptions[0].push(subscription);
    }
    if (
      subscription.status === CustomerSubscriptionStatus.InProgress ||
      subscription.status === CustomerSubscriptionStatus.Changed
    ) {
      return arrangedSubscrptions[1].push(subscription);
    }
    if (subscription.status === CustomerSubscriptionStatus.Cancelling) {
      return arrangedSubscrptions[2].push(subscription);
    }
    if (subscription.status === CustomerSubscriptionStatus.Cancelled) {
      return arrangedSubscrptions[3].push(subscription);
    }
  });
  arrangedSubscrptions[0].length > 1 && sortByDate(arrangedSubscrptions[0], 'deliveryDate');
  arrangedSubscrptions[1].length > 1 && sortByDate(arrangedSubscrptions[1], 'deliveryDate'); //
  arrangedSubscrptions[2].length > 1 && sortByPickUpDate(arrangedSubscrptions[2]); // arrange subscription as per early delivery
  arrangedSubscrptions[3].length > 1 && sortByDate(arrangedSubscrptions[3], 'cancellationDate'); //
  return arrangedSubscrptions.reduce((array, value) => array.concat(value), []);
};

export const getStatus = (status): string => {
  return statusDetails[status]?.name;
};

export const getStatusColor = (status): string => {
  return statusDetails[status]?.color;
};

export const isOnlyVirtualSubscriptions = (subscription): boolean => {
  return (
    Array.isArray(subscription?.items) &&
    subscription?.items.length === 1 &&
    subscription?.items[0]?.productId === 'club'
  );
};

export const formatLastFour = (num?: string | null) => (num || '').replace(/^(.*?)(.{4})(.{4})(.{4})$/, '$1 $2 $3 $4');
