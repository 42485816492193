import { Col, Container, Form, Input, Modal, Row, Select, TextfieldCommonMasks } from '@objectedge/ziyou-storefront-ds';
import { useCallback, useEffect } from 'react';
import {
  CountryCodeEnum,
  Customer,
  CustomerAddress,
  useCreateCustomerAddressMutation,
  useUpdateCustomerAddressMutation,
} from '~/operations';
import { createCustomerAddress as createCustomerAddressUpdater } from '~/state/cache/update';
import ufs from '~/utils/stateCodesMap';
import useAddressForm from '~/utils/useAddressForm';

export interface CreateOrEditAddressModalProps {
  address?: CustomerAddress;
  customer: Pick<Customer, 'firstname' | 'lastname' | 'phoneNumber'>;
  isOpen: boolean;
  closeModal: () => void;
}

export const CreateOrEditAddressModal = ({
  address = {},
  customer,
  isOpen,
  closeModal,
}: CreateOrEditAddressModalProps) => {
  const [updateCustomerAddress, { loading: updating }] = useUpdateCustomerAddressMutation();
  const [createCustomerAddress, { loading: creating }] = useCreateCustomerAddressMutation({
    update: createCustomerAddressUpdater,
  });
  const {
    form: { handleSubmit, watch, register, errors, setValue, reset },
    postalCodeError,
    fetchPostalInformation,
  } = useAddressForm({ address });

  useEffect(() => {
    if (isOpen) {
      reset(address);
      register('region');
    }
  }, [isOpen]); // reset form when opening

  const region = watch('region');
  const setRegion = useCallback((v) => {
    setValue('region', { region: v.label, region_id: parseInt(v.value) });
  }, []);
  const stateValue = ufs.find((u) => parseInt(u.value) === region?.region_id);

  const onSubmit = async (values: any) => {
    const payload = {
      ...values,
      ...customer,
      telephone: customer.phoneNumber,
      id: address?.id,
      region_id: values.region.region_id,
      street: [values.street],
      default_billing: values.default_billing === 'true',
      default_shipping: values.default_shipping === 'true',
      country_code: CountryCodeEnum.Br,
    };

    if (!address?.id) {
      await createCustomerAddress({ variables: payload });
    } else {
      await updateCustomerAddress({ variables: payload });
    }

    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      title={address.id ? 'Editar endereço' : 'Adicionar endereço'}
      okHandler={handleSubmit(onSubmit)}
      cancelHandler={closeModal}
      okButtonLabel="Salvar"
      cancelButtonLabel="Cancelar"
      okDisabled={updating || creating}
    >
      <Form>
        <Container>
          <Row className="no-x-margin-md">
            <Col lg={8} className="no-x-padding-md">
              <Input
                label="Nome do lugar"
                name="company"
                placeholder="Ex: Minha casa, trabalho, etc"
                ref={register}
                status={errors.company ? 'danger' : 'default'}
                helperText={errors.company?.message ?? ''}
                maxLength={255}
              />
            </Col>
            <Col lg={4} className="no-x-padding-md">
              <Input
                label="CEP"
                name="postcode"
                onChange={fetchPostalInformation}
                mask={TextfieldCommonMasks.cep}
                placeholder="00000-000"
                ref={register}
                register={register}
                status={errors.postcode || postalCodeError ? 'danger' : 'default'}
                helperText={postalCodeError ? 'CEP inválido' : errors.postcode?.message ?? ''}
              />
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={12} className="no-x-padding-md">
              <Input
                label="Endereço"
                name="street"
                placeholder="Rua ..."
                maxLength={54}
                ref={register}
                status={errors.street ? 'danger' : 'default'}
                helperText={errors.street?.message ?? ''}
              />
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={6} className="no-x-padding-md">
              <Input
                label="Número"
                name="number"
                placeholder="nº"
                maxLength={5}
                ref={register}
                status={errors.number ? 'danger' : 'default'}
                helperText={errors.number?.message ?? ''}
              />
            </Col>
            <Col lg={6} className="no-x-padding-md">
              <Input
                label="Complemento"
                name="complement"
                placeholder="Aptº, bloco, quadra..."
                maxLength={14}
                ref={register}
                status={errors.complement ? 'danger' : 'default'}
                helperText={errors.complement?.message ?? ''}
              />
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={5} className="no-x-padding-md">
              <Input
                label="Bairro"
                name="neighborhood"
                placeholder="Bairro"
                ref={register}
                maxLength={45}
                status={errors.neighborhood ? 'danger' : 'default'}
                helperText={errors.neighborhood?.message ?? ''}
              />
            </Col>
            <Col lg={5} className="no-x-padding-md">
              <Input
                label="Cidade"
                name="city"
                placeholder="Cidade"
                ref={register}
                maxLength={50}
                status={errors.city ? 'danger' : 'default'}
                helperText={errors.city?.message ?? ''}
              />
            </Col>
            <Col lg={2} className="no-x-padding-md">
              <Select
                label="Estado"
                placeholder="UF"
                value={stateValue}
                onChange={setRegion}
                options={ufs}
                status={errors.region ? 'danger' : 'default'}
                helperText={errors.region?.message}
                autocomplete="false"
              />
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={12} className="no-x-padding-md">
              <Input
                label="Detalhes adicionais para entrega"
                name="deliveryDetails"
                placeholder="Edifício sem elevador, tem cachorro na residência, telefonar quando chegar, etc."
                ref={register}
                maxLength={300}
                helperText=""
              />
            </Col>
          </Row>
          <input type="hidden" name="firstname" ref={register} />
          <input type="hidden" name="lastname" ref={register} />
          <input type="hidden" name="telephone" ref={register} />
          <input type="hidden" name="default_billing" ref={register} />
          <input type="hidden" name="default_shipping" ref={register} />
        </Container>
      </Form>
    </Modal>
  );
};

export default CreateOrEditAddressModal;
